import React from "react"
import PropTypes from "prop-types"
import i18n from "i18next"
import isEmpty from "lodash/isEmpty"
import find from "lodash/find"

import { generateButtonGroup } from "./SpecificAgreementActionButtonsHelper"
import { generateDataButton } from "../../utils"
import { consumeSpecificAgreementForm } from "../../hoc"
import { stepIndexes } from "../../context"

const isNextDisabled = (currentStep, school, company, recruiterEmailAddress, bySchoolAdmin) => {
  switch (currentStep) {
    case stepIndexes.SCHOOL:
      return isEmpty(school)
    case stepIndexes.COMPANY:
      return (
        isEmpty(company) ||
        !((company.company_name || company.name) && (company.company_tax_id || company.tax_id)) ||
        (bySchoolAdmin && (!recruiterEmailAddress || recruiterEmailAddress.length === 0))
      )
    case stepIndexes.REVIEW: {
      const { general_agreement: generalAgreement } = company

      return (
        (!generalAgreement ||
          !(generalAgreement.state === "active" || generalAgreement.state === "sent_for_signature" || generalAgreement.state === "school_review")) &&
        school.use_general_agreement
      )
    }
    default:
      return false
  }
}

export const ButtonsDefaultState = ({
  showRemoveButton,
  onRemove,
  onBack,
  lastStep,
  onNext,
  data: { school, company, recruiter_email_address: recruiterEmailAddress },
  currentStep,
  bySchoolAdmin,
  onSave,
}) => {
  let finalDataButtons = []
  if (showRemoveButton) {
    finalDataButtons.push(
      generateDataButton(i18n.t("spa:specific_agreements.form.buttons.shared.remove"), onRemove, "danger")
    )
  }
  const nextDisabled = isNextDisabled(currentStep, school, company, recruiterEmailAddress, bySchoolAdmin)
  finalDataButtons = [
    ...finalDataButtons,
    generateDataButton(i18n.t("spa:specific_agreements.form.buttons.shared.back"), onBack, "default", currentStep <= 2),
    currentStep === stepIndexes.REVIEW
      ? generateDataButton(i18n.t("spa:specific_agreements.form.buttons.shared.save"), onSave, "success")
      : null,
    generateDataButton(
      i18n.t(`spa:specific_agreements.form.buttons.shared.${lastStep ? "send" : "next"}`),
      nextDisabled ? null : onNext,
      "success",
      currentStep === stepIndexes.REVIEW ? false : nextDisabled,
      currentStep === stepIndexes.REVIEW
        ? {
          renderTooltip: nextDisabled
            ? () => <span>{i18n.t("spa:specific_agreements.form.buttons.shared.no_ga_tooltip")}</span>
            : undefined,
          tooltipId: "tooltip-send-disabled",
          tooltipPosition: "top",
          style: nextDisabled ? { opacity: 0.65, cursor: "not-allowed" } : undefined,
        }
        : undefined
    ),
  ]

  return generateButtonGroup(finalDataButtons)
}

ButtonsDefaultState.propTypes = {
  showRemoveButton: PropTypes.bool,
  onRemove: PropTypes.func,
  onBack: PropTypes.func,
  lastStep: PropTypes.bool,
  onNext: PropTypes.func,
  nextDisabled: PropTypes.bool,
  data: PropTypes.shape({
    school: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    recruiter_email_address: PropTypes.string,
  }).isRequired,
  bySchoolAdmin: PropTypes.bool,
  currentStep: PropTypes.number.isRequired,
  onSave: PropTypes.func,
}

ButtonsDefaultState.defaultProps = {
  showRemoveButton: false,
  onRemove: () => true,
  onBack: () => true,
  lastStep: false,
  onNext: () => true,
  recruiter_email_address: "",
  bySchoolAdmin: false,
  currentStep: PropTypes.number.isRequired,
  onSave: () => true,
}

export default consumeSpecificAgreementForm(ButtonsDefaultState, [ "school", "company", "recruiter_email_address" ])
